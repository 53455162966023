<template>
  <div>
    <div class="time-line">
      <div
        v-for="(i, index) in listData"
        :key="index"
        class="time-line-div canRecordcon"
      >
        <div class="time-line_l">
          <p class="time">{{ i.createdTime }}</p>
          <p class="name">{{ i.applier }}</p>
        </div>
        <p class="line"></p>
        <div class="time-line_r">
          <div class="time-line_con">
            <el-row :gutter="20" :span="24">
              <el-col :span="17" style="padding-bottom: 55px">
                <div class="canRecordBox">
                  <div class="canRecord">申请信息</div>
                  <div class="canRecordcon">
                    <el-form label-width="110px" label-position="right">
                      <el-row>
                        <el-col :span="12">
                          <el-form-item label="项目名称：">
                            {{ i.projectName ? i.projectName : "-" }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="候选人：">
                            {{ i.resumeName ? i.resumeName : "-" }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="开票金额：">
                            {{ i.payAmount ? i.payAmount : "-" }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="申请原因：">
                            {{
                              i.noContractApplyReason
                                ? i.noContractApplyReason
                                : "-"
                            }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="约定签约时间：">
                            {{ i.agreedSignDate ? i.agreedSignDate : "-" }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item label="情况说明：">
                            {{
                              i.noContractComment ? i.noContractComment : "-"
                            }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="24">
                          <el-form-item label="证明材料：">
                            <p
                              class="tabHref mgr10"
                              v-for="(item, index) in i.noContractProveFiles"
                              :key="index"
                            >
                              <span @click="previewOpen(item)"
                                >{{ item.fileName }}
                              </span>
                              <i
                                @click="handleDown(item)"
                                class="el-icon-download"
                                style="margin-left: 15px"
                              ></i>
                            </p>
                            <p v-if="i.noContractProveFiles == null">-</p>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="关联合同：">
                            {{
                              i.noContractRelatedContractName
                                ? i.noContractRelatedContractName
                                : "-"
                            }}
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </div>
              </el-col>
              <el-col :span="7" style="padding-left: 0">
                <div class="canRecordBox">
                  <div class="canRecord">审批流程</div>
                  <el-timeline class="canRecordcon">
                    <el-timeline-item
                      v-for="(activity, index) in i.approvalFlowList"
                      :key="index"
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                    >
                      <div class="approvalTime">
                        {{ activity.approvalTime }}
                      </div>
                      <div style="color: #606266; margin: 5px 0">
                        {{ activity.content }}
                      </div>
                      <div
                        v-if="activity.comment"
                        class="clearfix"
                        :style="
                          activity.result == 'REJECTED'
                            ? 'color:#e60012'
                            : 'color:#526ecc'
                        "
                      >
                        <span class="fl" style="display: inline-block"
                          >备注：</span
                        >
                        <div
                          class="fl"
                          style="width: calc(100% - 65px)"
                          v-html="activity.comment"
                        ></div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="dott"></div>
    </div>
  </div>
</template>

<script>
import * as api from "../../api/http";
import {
  financeTaskListByCompany, //查看客户无合同开票审记录
} from "../../api/api";
export default {
  props: ["projectResumeId", "applyId"],
  data() {
    return {
      listData: [],
      ApiBaseUrl: api.ApiBaseUrl,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    //获取数据
    loadList() {
      financeTaskListByCompany({
        projectResumeId: this.projectResumeId,
        applyId: this.applyId,
      }).then((res) => {
        if (res.success) {
          this.listData = res.result;
        }
      });
    },
    handleDown(row) {
      //下载文件
      location.href =
        api.ApiBaseUrl +
        "/api/file/download?fileName=" +
        encodeURIComponent(row.fileName) +
        "&filePath=" +
        row.filePath;
    },
    //预览
    previewOpen(item) {
      // let item = this.versionVal;
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        //wps预览
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            api.PreviewUrl +
            item.filePath,
          "_blank"
        );
      } else if (fileType == "pdf") {
        //pdf预览
        window.open(
          "/pdf/web/viewer.html?file=" + api.PreviewUrl + item.filePath,
          "_blank"
        );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        //图片预览
        window.open(api.PreviewUrl + item.filePath, "_blank");
      } else if (fileType) {
        this.$message.error("不支持预览");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canRecordBox {
  border: 1px solid #eee;
  position: relative;
  line-height: normal;
  .canRecord {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    background: #f5f5f5;
  }
  .canRecordcon {
    font-size: 13px;
    padding: 0 20px;
    max-height: 60vh;
    overflow: auto;
    :deep(.el-form-item) {
      margin-bottom: 0px;
    }
    :deep(.el-timeline-item) {
      padding-bottom: 10px;
    }
  }
}
.time-line {
  position: relative;
  padding: 0 15px;
}
.time-line-div {
  position: relative;
  min-height: 85px;
  margin-top: 15px;
  &:nth-child(1) {
    margin-top: 0 !important;
  }
  .time-line_l {
    position: absolute;
    left: 0;
    width: 135px;
    text-align: right;
    p {
      margin: 0;
      line-height: 1;
    }
    .time {
      padding-top: 24px;
      font-size: 12px;
      color: #999;
    }
    .name {
      font-size: 13px;
      margin-top: 10px;
      color: #606266;
    }
  }
  .line {
    position: absolute;
    left: 155px;
    width: 12px;
    height: 12px;
    top: 20px;
    background: #e4e7ed;
    border-radius: 50%;
    z-index: 10;
  }
  .time-line_r {
    position: relative;
    left: 191px;
    width: calc(100% - 180px);
    .time-line_con {
      // border: 1px solid #e5e5e5;
      padding: 10px;
      .r_title {
        height: 40px;
        line-height: 40px;
        background-color: #f5f5f5;
        margin: 0;
        padding-left: 10px;
        border-bottom: 1px solid #e5e5e5;
      }
      .r_con {
        padding: 15px 20px;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
}
.dott {
  position: absolute;
  width: 5px;
  left: 173px;
  height: calc(100% - 45px);
  top: 15px;
  bottom: 5px;
  z-index: 1;
  background-color: #e5e5e5;
}
</style>
